import React, { useEffect } from "react"
import SEO from "../../components/Seo/Seo"

import { navigate } from "gatsby"

const contactMetadata = {
  Title: "Contact - Flipbase",
  Keywords: ["Flipbase Contact", "Contact Flipbase"],
  Description: "Kunnen we je ergens mee helpen? Neem contact met ons op!",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/Contact")
  }, [])

  return (
    <SEO
      title={contactMetadata.Title}
      description={contactMetadata.Description}
      image={contactMetadata.Image}
      keywords={contactMetadata.Keywords}
    />
  )
}
